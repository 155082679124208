<template>
  <el-select
    ref="selectTree"
    style="width: 100%"
    v-model="formData[item.prop]"
    :placeholder="item.placeholder"
    :class="item.class"
    :style="item.value"
    :allow-create="item.allowCreate"
    :clearable="item.clearable"
    :disabled="item.disabled"
    :multiple="item.multiple"
    :multiple-limit="item.multipleLimit"
    :remote-method="item.remoteMethod || emptyFn"
    :filterable="item.filterable"
    :filter-method="selectFilterData"
    :remote="item.remote"
    :loading="item.loading"
    size="medium"
    @change="change"
    @focus="focus"
  >
    <el-option
      v-for="option in optionData(item.options)"
      :key="option['value']"
      :label="option['label']"
      :value="option['value']"
      :disabled="option.disabled"
      style="display: none"
      size="medium"
    >
    </el-option>
    <el-tree
      class="main-select-el-tree"
      ref="selectelTree"
      :highlight-current="true"
      :data="item.options"
      :filter-node-method="filterNode"
      :props="item.treeProps || treeProps"
      :node-key="item.dictValue || 'value'"
      :show-checkbox="item.multiple"
      :default-checked-keys="item.multiple ? formData[item.prop] : []"
      :current-node-key="formData[item.prop]"
      :expand-on-click-node="item.expandOnClickNode || true"
      default-expand-all
      @node-click="handleNodeClick"
      @current-change="handleCheckChange"
    />
  </el-select>
</template>

<script type="text/ecmascript-6">
import mixins from '../mixins';

export default {
  name: 'mElTreeSelect',
  mixins: [mixins],
  components: {},
  props: {},
  data() {
    return {
      treeProps: {
        children: 'children',
        label: 'label',
      },
    };
  },
  computed: {
    currentValue() {
      return this.formData[this.item.prop];
    },
  },
  watch: {
    currentValue(value) {
      if (this.item.multiple) {
        if (value?.length > 0) {
          this.$refs['selectelTree']?.setCheckedKeys(value);
        } else {
          this.$refs['selectelTree']?.setCheckedKeys([]);
        }
      } else {
        if (value) {
          this.$refs['selectelTree']?.setCurrentKey(value);
        } else {
          this.$refs['selectelTree']?.setCurrentKey(null);
        }
      }
    },
  },
  mounted() {
    this.$nextTick(() => {
      if (this.formData[this.item.prop] && !this.item.multiple) {
        // 单选
        this.$refs['selectelTree']?.setCurrentKey(this.formData[this.item.prop]); // 设置默认选中
      } else if (this.formData[this.item.prop]) {
        //多选
        this.$refs['selectelTree']?.setCheckedKeys(this.formData[this.item.prop]);
      }
    });
  },
  methods: {
    /**
     * 树形转平面的迭代方法
     * option 1的el-option需要此方法绑定数据
     */
    optionData(array, result = []) {
      const { dictLabel, dictValue, treeProps } = this.item;
      if (array?.length > 0) {
        array.forEach((item) => {
          result.push({
            label: item[dictLabel || 'label'],
            value: item[dictValue || 'value'],
          });
          if (item[treeProps?.children || 'children'] && item[treeProps?.children || 'children'].length !== 0) {
            this.optionData(item[treeProps?.children || 'children'], result);
          }
        });
      }
      return JSON.parse(JSON.stringify(result));
    },

    /**
     * 下拉框搜索
     */
    selectFilterData(val) {
      // 当搜索框键入值改变时，将该值作为入参执行树形控件的过滤事件filterNode
      this.$refs['selectelTree'].filter(val);
    },

    // 点击节点的响应
    handleNodeClick(data) {
      const value = data[this.item.dictValue || item.value];
      this.$set(this.formData, this.item.prop, value);
      this.selectEvent({
        type: 'change',
        prop: this.item.prop,
        value: this.formData[this.item.prop],
        info: data,
      });
      // 选中后失去焦点，隐藏下拉框
      this.$refs.selectTree.blur();
      // 把数据还原
      this.selectFilterData('');
    },

    // 节点选中状态发生变化时的回调
    handleCheckChange(node, checked) {
      console.log(node, checked, 'vvvvvvv');
    },

    // 模糊查询（搜索过滤），实质为筛选出树形控件中符合输入条件的选项，过滤掉其他选项
    filterNode(value, data) {
      if (!value) return true;
      const { dictLabel } = this.item;
      const filterRes = data[dictLabel || 'label'].indexOf(value) !== -1;
      return filterRes;
    },
    change() {
      this.selectEvent({
        type: 'change',
        prop: this.item.prop,
        value: this.formData[this.item.prop],
        info: {},
      });
    },
    focus() {
      this.selectEvent({
        type: 'focus',
        prop: this.item.prop,
        value: this.formData[this.item.prop],
      });
    },
    emptyFn() {},
  },
};
</script>

<style scoped lang="scss">
::v-deep {
  .el-select__tags-text {
    font-size: $fontSize_14;
  }
}
</style>
