var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "el-select",
    {
      ref: "selectTree",
      class: _vm.item.class,
      staticStyle: { width: "100%" },
      style: _vm.item.value,
      attrs: {
        placeholder: _vm.item.placeholder,
        "allow-create": _vm.item.allowCreate,
        clearable: _vm.item.clearable,
        disabled: _vm.item.disabled,
        multiple: _vm.item.multiple,
        "multiple-limit": _vm.item.multipleLimit,
        "remote-method": _vm.item.remoteMethod || _vm.emptyFn,
        filterable: _vm.item.filterable,
        "filter-method": _vm.selectFilterData,
        remote: _vm.item.remote,
        loading: _vm.item.loading,
        size: "medium",
      },
      on: { change: _vm.change, focus: _vm.focus },
      model: {
        value: _vm.formData[_vm.item.prop],
        callback: function ($$v) {
          _vm.$set(_vm.formData, _vm.item.prop, $$v)
        },
        expression: "formData[item.prop]",
      },
    },
    [
      _vm._l(_vm.optionData(_vm.item.options), function (option) {
        return _c("el-option", {
          key: option["value"],
          staticStyle: { display: "none" },
          attrs: {
            label: option["label"],
            value: option["value"],
            disabled: option.disabled,
            size: "medium",
          },
        })
      }),
      _vm._v(" "),
      _c("el-tree", {
        ref: "selectelTree",
        staticClass: "main-select-el-tree",
        attrs: {
          "highlight-current": true,
          data: _vm.item.options,
          "filter-node-method": _vm.filterNode,
          props: _vm.item.treeProps || _vm.treeProps,
          "node-key": _vm.item.dictValue || "value",
          "show-checkbox": _vm.item.multiple,
          "default-checked-keys": _vm.item.multiple
            ? _vm.formData[_vm.item.prop]
            : [],
          "current-node-key": _vm.formData[_vm.item.prop],
          "expand-on-click-node": _vm.item.expandOnClickNode || true,
          "default-expand-all": "",
        },
        on: {
          "node-click": _vm.handleNodeClick,
          "current-change": _vm.handleCheckChange,
        },
      }),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }